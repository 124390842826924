<template>
  
  <div class="contailPanelV">
  <div class="intruduce">
   建立人工智能基础技术领域的基础算法库和测试训练数据集以及专项解决方案资源库，
   同时建立面向重点行业领域的专用算法库、模型库及数据集等行业资源库。
  </div>
           <div class="search-panel">
 
                <search-item class="search-item"
                 label="资源库"
                 :items="typeList"
                 :currentValue="model.respTypes"
                 @handleSearchItem="handelTypeChange"/>   
 
               <search-item class="search-item"
                 label="分类"
                 :items="specList"
                 :currentValue="model.spec"
                 @handleSearchItem="handelSpecChange"/>  
               
               <search-item class="search-item" v-show="techList"
                 label="资源库子类"
                 :items="techList"
                 :currentValue="model.respTechnology"
                 @handleSearchItem="handelTechChange"/>  
 
                <search-text class="search-text"  @search="handleSearchText" :value="model.title" />
           </div>
           <!-- //引入内容组件 -->
           <page-list
               @pageChanged="handlePageChange"
               :detailPath="detailPath"
               :list="list"
               :total="total"
               :current="model.curPage" 
               :size="model.pageSize"
           >
             <template v-slot="scope">
               <div class="title-flex">
                 <div v-if="0==scope.row.index%3" class="avatar bg0">{{scope.row.item.title.charAt(0)}}</div>
                 <div v-if="1==scope.row.index%3" class="avatar bg1">{{scope.row.item.title.charAt(0)}}</div>
                 <div v-if="2==scope.row.index%3" class="avatar bg2">{{scope.row.item.title.charAt(0)}}</div>
                 <div class="title-desc" @click="detailLink(scope.row.item.id)">
                   <div class="title-box">
                     <div class="title" > {{scope.row.item.title}}</div>
                     <div>
                       <span class='title-tag' v-for="(type, index) in scope.row.item.types" :key="index">
                         {{type.respTypes}} 
                       </span> 
                     </div>
                     <div>
                       <span class='title-tag' v-for="(type, index) in scope.row.item.types" :key="index">
                         {{type.respTechnology}}
                       </span>  
                     </div>
                     <div>
                       <span class='title-tag-info' v-for="(type, index) in scope.row.item.formats" :key="index">
                        {{type}}
                       </span> 
                     </div>
                   </div>
                   <div class="summary"> {{scope.row.item.description}}</div>
                 </div>                
               </div>
                <div class="time" v-if="scope.row.item.createTime">
                     {{ scope.row.item.createTime| FormatDate('yyyy-MM-dd')}}
                 </div>  
             </template>
           </page-list>
 
   </div>
 </template>
 
 <script>
 
 import pageList from "@/components/list/complexPageList"; //
 import searchItem from '@/components/all/searchItem'
 import searchText from '@/components/all/search'
 
 export default {
   name: "DatasetList",  
   components: {
     searchText,
     searchItem,
     pageList, //引入内容组件
 
   },
   props: {
     // 当前的页码(2级菜单)
     chinum: {
       typeof: [Number],
       default: 0,
     },
   },
   data() {
     return {
       primary: 1, //一级菜单的选中样式
       istrue: true, //刷新页面
       crumblist: [
         {
           name: "首页",
           path: "/home",
           query: {},
         },
         {
           name: "数据集和模型列表",
           path: "",
           query: {},
         },
       ],
       detailPath: '/dev/dataset/detail',
       specList: [
           {label: "全部", value: ''},
           {label: "数据集", value: 'DATASET'},
           {label: "模型", value: 'MODEL'}
       ],
       typeList:  [
           {label: "全部", value: ''},
           {label: "基础库", value: '基础技术领域资源库'},
           {label: "行业库", value: '行业领域资源库'},
           {label: "专项解决方案", value: '专项解决方案资源库'},
       ],
       typeSubList: {
         // '':[],
         基础技术领域资源库:[],
         行业领域资源库:[],
         专项解决方案资源库:[],
       },
 
       techList: null,
       list: [], 
       total: 0,
       model: {
         spec: '',    //DATASET |MPDE:
         respTypes: '',   //基础技术领域资源库
         respTechnology: '', //神经网络
         title:'',
         curPage: 1,
         pageSize: 10,
       },
       // pagination: {
       //   current: 1, //当前页码
       //   size: 20, //每页条数
       //   total: 0, //总数
       // },
     }
   },
   created() {
     // console.log(" created  pagination ", this.pagination)
     this.getDict()
     this.getList()
   },
   
   mounted() {
   },
 
   methods: {
     detailLink(id) {
       var path = this.detailPath+"/"+id;
       console.log(" detail link ", path)
       this.until.jump.call(this, path, "push");
     },
 
     
     handelTechChange(e) {
       console.log(" handelTechChange:"+e)
       this.model.respTechnology = e 
       this.getList() 
     },
     
     handelTypeChange(e) {
       console.log(" handelTypeChange:"+e)
       this.model.respTypes = e 
       this.model.respTechnology=''
       // console.log(" typeSubList ", this.typeSubList)
       // console.log(" sublist ", this.typeSubList[e])
       // console.log("  contain key :" )
       this.techList = this.typeSubList[e]
       this.getList() 
     },
     handelSpecChange(e) {
       console.log(" handelSpecChange:"+e)
       this.model.spec = e 
       this.getList() 
     },
     handleSearchText(e){
       console.log(" handleSearchText:"+e)
       // console.log(" this.model.title:" +this.model.title)
       this.model.title = e 
       this.getList() 
     },
      //咨询问题类型筛选
       handelSearchChange(e){
         console.log(" handelSearchChange",  e)
         this.model.text = e
         this.getList()
       },
 
      //   改编页码之后
     handlePageChange(e) {
       console.log("  handlePageChange ",  e)
       // console.log(" model :" , this.model)
       this.model.curPage = e.current;
       this.model.pageSize = e.size;
       this.getList()
     },
     // 获取当前数组
     getList() {
       console.log("  param ", this.model)
       this.$api.getDataset(this.model).then((res) => {
         this.list = res.data.dataList
         this.total = res.data.totalCount
         // console.log( "---------- this.list ", this.list)
         // console.log( "---------- this.total ", this.total)
         
       });
 
     },
     
     getDict(){
       // console.log(" keys ",  Object.keys(this.typeSubList))
       // this.typeSubList['']=[{label: "全部", value: ''}];
       Object.keys(this.typeSubList).forEach(key => {
         if(!key)
           return 
         this.$api.getDatasetDict(key).then((res) => {
           console.log(" get dict ", res)
           let sp = res.data.map(item=> {
             return  { label: item.k, value: item.v }
           })
           this.typeSubList[key] = [
             {label: "全部", value: ''},
             ...sp
           ]
           // this.typeSubList[''].push(...sp)
         });
       });
 
       // this.techList = this.typeSubList['']
           
       // console.log("   this.typeSubList[''] ",  this.typeSubList[''])
       // console.log("  this.subList ",this.typeSubList)
       
       // http://127.0.0.1/data-repository-api/api/repo/dict/query/%E9%A1%B9%E7%9B%AE%E7%B1%BB%E5%88%AB
 
       // 专项解决方案资源库
 
       //http://127.0.0.1/data-repository-api/api/repo/dict/query/%E5%9F%BA%E7%A1%80%E6%8A%80%E6%9C%AF%E9%A2%86%E5%9F%9F%E8%B5%84%E6%BA%90%E5%BA%93
     },
 
   },
 };
 </script>
 
 <style scoped lang="less">
 .time {
     font-size: 0.675rem;
     min-width: 5rem;
   }
 
   .title-flex{
     display: flex;
     justify-content: space-between;
     padding-bottom: 0.5rem;
     align-items: center;
     .avatar{
       min-width: 2rem;
       line-height: 2rem;
       // min-height: 2rem;
       text-align: center;
       // align-items: center;
       border-radius: 50%;
       margin-right: 1rem;
     }
 
     .bg0 {
       border-bottom: 0.0625rem solid #ececec;
       border: 1px solid #f5f5f5;
       background: #e8eaf6 ;
     }
     .bg1 {
       border-bottom: 0.0625rem solid #ececec;
       border: 1px solid #f5f5f5;
       background: #e3f2fd ;
     }
     .bg2 {
       border-bottom: 0.0625rem solid #ececec;
       border: 1px solid #f5f5f5;
       background: #f3e5f5 ;
     }
     .title-desc:hover {
       cursor: pointer;
       // color: #00f;
       // text-decoration: underline;
     }
     .title-desc{
       
       .title-box{
         display: flex;
         align-items: center;
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
         // .title{
         //   // border-bottom: 0.0625rem solid #ececec;
         //   // line-height: 38px;
         // }
         .title-tag-info{
           font-size: 12px;
           background-color: #f4f4f5;
           border-color: hsl(240, 5%, 92%);
           color: #909399;
           border-radius: 0.25rem;
           padding: 0 0.2rem;
           margin-left: 1rem;
           line-height: 1.25rem;
           height: 1.25rem;
         }
         .title-tag {
           font-size: 12px;
           color: #0063ff;
           background-color: #fff;
           border-color: #99c1ff;
           border-width: 1px;
           border-style: solid;
           border-radius: 0.25rem;
           margin-left: 1rem;
           // height: 2rem;
           padding: 0 0.2rem;
           line-height: 1.25rem;
           height: 1.25rem;
           display: inline-block;
           box-sizing: border-box;
           white-space: nowrap;
         }
       }
       .summary {
         font-size: 12px;
         line-height: 150%;
         padding: 0 2rem 0 0 ;
       }
     }
   }
   .search-panel{
     padding: 0 0.9375rem 0 0.9375rem;
     .search-text {
       width: 30rem;
       height: 2.1875rem;
       margin: 0.5rem 0;
     }
     .search-item {
       padding: 0.25rem 0;
     }
   }
   .detailbox {
       min-height: 50vh;
     // 面包屑
     /deep/ .more {
       display: none;
     }
     /deep/ .cardbox {
       padding: 0;
       .cardhead {
         padding-left: 0.375rem;
       }
       .cardcent {
         margin-top: 1rem;
         padding-left: 0.375rem;
 
         .hangbox {
           height: 1.875rem;
           margin: 0;
         }
       }
     }
   }
 @media screen and (min-width: 1000px) {
   
 }
 
 @media screen and (max-width: 999px) {
   .time {
     display:none;
   }
   .title-flex{
     .avatar {
       display:none;
     }
     .title-desc{
       .title-box{
         .title-tag, .title-tag-info {
           display:none;
       
         }
       }
     }
   }
 }
 </style>
 